import { useEffect, useState, useContext, useMemo } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import {
  Container,
  Box,
  Stack,
  Modal,
  Typography,
  TextField,
  Button,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { Like } from './like'
import { TagItem } from './tagitem'
import _ from 'lodash'
import $axios from '../../tools/axiosWrapper'
import TagIcon from '@mui/icons-material/Tag'

export const Tags = ({ user }) => {
  const theme = useTheme()
  const [tags, setTags] = useState(false)
  const [load, setLoad] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    setLoad(true)
    $axios
      .get('/collection/list/' + 'tags')
      .then((response) => {
        const list = _(response.data?.tags)
          .mapValues((value, id) => _.merge({}, value, { id }))
          .values()
          .value()
        setTags(list)
      })
      .catch((error) => {})
  }, [])

  return (
    <Stack
      style={{
        width: '100vw',
        minHeight: '100vh',
      }}
      alignItems={'center'}
      sx={{ pr: 8 }}
    >
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ mt: 4 }}
      >
        <TagIcon sx={{ transform: 'scale(1.5)' }} />
        <Typography variant={'h4'}>tags</Typography>
      </Stack>

      <Stack
        sx={{
          mt: 10,
          px: 4,
          py: 8,
          width: '80vw',
          maxWidth: 1000,
          // background: theme.palette.purple[0],
          borderRadius: '10px',
          overflowY: 'scroll',
        }}
        spacing={8}
      >
        {/* {tags && tags.length == 0 && (
          <Stack sx={{ width: '100%' }} alignItems='center'>
            <Typography>no tagged documents</Typography>
          </Stack>
        )} */}
        {tags && tags.map((d, i) => <TagItem d={d} />)}
      </Stack>
    </Stack>
  )
}
