import React, { useEffect, useState, useContext, useMemo } from 'react'

import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { Stack, Button, CircularProgress } from '@mui/material'
import _ from 'lodash'
import CloseIcon from '@mui/icons-material/Close'
import $axios from '../../tools/axiosWrapper'
import { useNavigate } from 'react-router'

export const SideItemTag = ({ tag, d, i, reff, imgs, current, setCurrent }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [load, setLoad] = useState(false)

  console.log(d)

  // const removeImg = (d) => {
  //   $axios
  //     .get('/tag/remove/' + d.id + '|' + e)
  //     .then((response) => {})
  //     .catch((error) => {})
  // }

  return (
    <>
      <motion.div
        whileTap={{ scale: 0.95 }}
        transition={{ duration: 0.1 }}
        onClick={() => setCurrent(d)}
        key={d.imageid + '-' + i}
        style={{ cursor: 'pointer', scrollMarginBottom: '160px' }}
        id={d.imageid.replace('_w32', '')}
        ref={(el) => (reff.current[i] = el)}
      >
        <div style={{ position: 'relative' }}>
          <img
            onError={(err) => console.log(err)}
            src={d.imgurl}
            alt=' '
            onDragStart={(event) => event.preventDefault()}
            style={{
              userSelect: 'none',
              borderColor:
                current && current.imageid == d.imageid
                  ? 'orange'
                  : 'transparent',
              borderWidth: 6,
              borderStyle: 'solid',
              opacity: current && current.imageid == d.imageid ? 1 : 0.8,
            }}
          />
          <div style={{ position: 'absolute', bottom: -5, right: -12 }}>
            {load ? (
              <CircularProgress size={20} sx={{ marginRight: 3 }} />
            ) : (
              <Button
                onClick={() => {
                  setLoad(true)
                  $axios
                    .get('/tag/remove/' + tag + '|' + d.imageid)
                    .then((response) => {})
                    .catch((error) => {})
                    .finally(() => {
                      setLoad(false)
                      navigate(0)
                    })
                }}
              >
                <CloseIcon />
              </Button>
            )}
          </div>
        </div>

        <motion.div
          style={{
            textAlign: 'center',
            width: '100%',
            marginBottom: '12px',
            marginTop: '-4px',
            fontWeight: current && current.imageid == d.imageid ? 800 : 400,
          }}
        >
          {d.imageid.replace('_w32', '')}
        </motion.div>
      </motion.div>
    </>
  )
}
