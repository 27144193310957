import { useEffect, useState, useContext, useMemo } from 'react'

import { Container, Grid, Typography, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
export const Motivation = () => {
    const theme = useTheme()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Stack sx={{ minHeight: '100vh', py: 12 }} alignItems={'center'}>
                <Typography
                    variant='h2'
                    sx={{ fontWeight: 800, color: theme.palette.purple[5], mb: 8 }}
                >
                    MOTIVATING QUESTIONS
                </Typography>

                <Stack sx={{ width: 700 }} spacing={7}>
                    <Stack spacing={2}>
                        <Typography variant='body1'>
                            Our goal is to provide the FBarchive to researchers,{" "}
                            journalists, civil society, and the public,{" "}
                            so we can all work together to resolve the many technology-society{" "}
                            clashes exposed in the documents.{" "}
                            The information in the archive highlights a range of tensions exposed by
                            Facebook’s policies and design – between the financial incentives of
                            advertising and the needs of human users, between content moderation
                            and free speech, between corporate growth and social responsibility,
                            between the economics of the attention economy and our mental health.
                            Below is a series of research questions generated by Dr. Latanya Sweeney,
                            meant to provide a jumping off point for users to guide exploration,
                            inquiry, and project development.
                        </Typography>
                    </Stack>
                    <Stack spacing={2}>
                        <Typography variant='body1'>
                            <ol>
                                <li>
                                    Is viral content more likely to increase Facebook’s revenues?
                                    How does Facebook handle this tension?
                                    Under what circumstances are the needs of
                                    human users traded for corporate revenue?
                                </li>
                                <li>
                                    How does content moderation scale? How is it deployed at Facebook across countries,
                                    groups of people within countries, and different languages and dialects?
                                    How does Facebook assess its content moderation?
                                    What kinds of content pose the greatest problems?
                                    How has Facebook addressed ongoing challenges to content moderation?
                                </li>
                                <li>
                                    More generally, Facebook faces a lot of adverse issues.
                                    How is the company organized?
                                    How many people are assigned to address societal issues?
                                </li>
                                <li>
                                    Which groups of people use Facebook the most?
                                    Do these groups need special attention?
                                    If so, is more or less effort done to understand the needs
                                    of these groups or to make sure they participate in decision-making,
                                    are present in the company, or are part of relevant focus groups?
                                </li>
                                <li>
                                    At least 95 countries are identified in the Facebook documents.
                                    What are the top concerns Facebook considers for people in these countries on the platform?
                                    Are the concerns and the way Facebook addresses them similar or different across countries?
                                </li>
                                <li>
                                    How does Facebook define and measure misinformation?
                                    How does Facebook’s identification and handling of misinformation
                                    compare to misinformation identification and handling in
                                    times before digital technology? How does it compare across its
                                    own platforms and to other online platforms?
                                </li>
                                <li>
                                    Facebook makes its revenues primarily through advertising.
                                    What is the relationship between advertising and the spread of misinformation?
                                    How often and to what extent are advertisements used to spread misinformation?
                                    What are the economics involved?
                                </li>
                                <li>
                                    What is the nature and extent of violent, extremist, hate-speech,
                                    and human-trafficking content found on Facebook?
                                    Can the platform identify it? If so, how and what
                                    can or does Facebook do about it?
                                </li>
                                <li>
                                    Violence and political unrest exists around the world and
                                    is evidenced within the Facebook documents.
                                    What is the nature and extent that Facebook itself plays
                                    in the proliferation of these tensions, if any? What role
                                    could Facebook play to help reduce these tensions?
                                </li>
                                <li>
                                    Do human users actually trust Facebook with their data and attention?
                                    Do they trust Facebook more or less than other online platforms?
                                    How has trust in Facebook by human users changed over time?
                                </li>
                                <li>
                                    How does Facebook learn about problems caused by their platforms,
                                    assess them, seek internal remedies, and accept or reject
                                    internal results and remedies? What knobs and
                                    internal policies are available?
                                </li>
                                <li>
                                    What are alternative models for Facebook to use for content moderation,
                                    decisions about what will appear in a user’s news feed,
                                    delivery of advertisements, and the identification
                                    and handling of misinformation?
                                </li>
                            </ol>
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}
