import { useEffect, useState, useContext, useMemo } from 'react'
import {
  Container,
  Box,
  Stack,
  Modal,
  Typography,
  TextField,
  Button,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import _ from 'lodash'
import $axios from '../../tools/axiosWrapper'

export const Glossary = ({ glossary, setGlossary }) => {
  const theme = useTheme()
  const [terms, setTerms] = useState(null)

  const style = {
    position: 'absolute',
    right: 30,
    bottom: 30,
    width: 700,
    bgcolor: theme.palette.purple[1],
    pl: 6,
    pr: 3,
    py: 4,
    height: 600,
    borderRadius: '14px',
    overflow: 'hidden',
    outline: 'none',
  }

  useEffect(() => {
    $axios.get('/collection/glossary').then((response) => {
      setTerms(response.data)
    })
  }, [])
  return (
    <>
      <Modal
        open={glossary}
        onClose={() => setGlossary(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Stack sx={style} justifyContent={'center'} spacing={2}>
          <Stack sx={{ overflow: 'hidden', overflowY: 'scroll' }}>
            {terms?.map((d, i) => (
              <Stack spacing={0.5} sx={{ my: 1, pr: 4 }}>
                <motion.div
                  style={{
                    fontSize: 15,
                    fontWeight: 600,
                    color: theme.palette.white[0],
                  }}
                >
                  {d.term}
                </motion.div>
                <motion.div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: theme.palette.white[3],
                  }}
                >
                  {d.description}
                </motion.div>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Modal>
    </>
  )
}
