import { useEffect, useState, useContext, useMemo } from 'react'
import { Routes, Route, Outlet, Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { Library2 } from './library2'
import { Loading } from './loading'
import './home.css'
import $axios from '../../tools/axiosWrapper'
import LoginIcon from '@mui/icons-material/Login'
import InfoIcon from '@mui/icons-material/Info'
import { Stack, Button, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

export const Login = ({ user }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  console.log('user', user)

  const POINTS = [
    {
      start: {
        x1: 0,
        y1: 32,
        x2: 64,
        y2: 32,
      },
      finish: {
        x1: 20.37,
        y1: 9,
        x2: 54.71,
        y2: 9,
      },
    },
    {
      start: {
        x1: 0,
        y1: 36,
        x2: 64,
        y2: 36,
      },
      finish: {
        x1: 9.56,
        y1: 20,
        x2: 47.12,
        y2: 20,
      },
    },
    {
      start: {
        x1: 0,
        y1: 40,
        x2: 64,
        y2: 40,
      },
      finish: {
        x1: 16,
        y1: 32,
        x2: 60,
        y2: 32,
      },
    },
    {
      start: {
        x1: 0,
        y1: 44,
        x2: 64,
        y2: 44,
      },
      finish: {
        x1: 7.79,
        y1: 44,
        x2: 54.71,
        y2: 44,
      },
    },
  ]

  return (
    <>
      <Stack
        sx={{ height: '70vh' }}
        direction='row'
        spacing={4}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Stack alignItems={'center'} spacing={5}>
          <Stack alignItems={'center'} spacing={1}>
            <motion.svg
              alt='fb archive logo'
              viewBox='0 0 64 50'
              style={{
                width: '70px',
                strokeWidth: 4.5,
                stroke: theme.palette.white[4],
              }}
            >
              {POINTS.map((d, i) => (
                <line key={i} {...d.finish} />
              ))}
            </motion.svg>
            <Typography variant={'h4'} sx={{ color: theme.palette.white[4] }}>
              fbarchive.org
            </Typography>
          </Stack>

          <Typography
            sx={{
              width: '400px',
              fontSize: '14pt',
              color: theme.palette.white[0],
              textAlign: 'center',
            }}
          >
            an online collection of internal Facebook documents
          </Typography>
        </Stack>

        <div
          style={{
            width: 2,
            height: '400px',
            background: theme.palette.purple[3],
          }}
        ></div>
        <Stack spacing={2}>
          <Button
            variant={'contained'}
            size={'large'}
            onClick={() => {
              $axios.get('/auth/login').then((response) => {
                window.location.href = response.data.login_url
              })
            }}
          >
            enter
            <LoginIcon sx={{ ml: 1 }} />
          </Button>
          <Button
            variant={'outlined'}
            size={'large'}
            onClick={() => navigate('/history')}
          >
            learn more
            <InfoIcon sx={{ ml: 1 }} />
          </Button>
        </Stack>
      </Stack>
    </>
  )
}
