import React, { useEffect, useState, useContext, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import _ from 'lodash'
import { SideItem } from './sideimg'
import { SideItemTag } from './sideimgtag'

export const Side = ({ tag, imgs, current, setCurrent }) => {
  const theme = useTheme()
  const ref = useRef([])
  const location = useLocation()

  const [isTag, setIsTag] = useState(false)

  useEffect(() => {
    let docid = location.pathname.replace('/doc/', '')
    setIsTag(/^\d+$/.test(docid))
  }, [])

  useEffect(() => {
    ref.current = ref.current.slice(0, imgs.length)
  }, [imgs])

  useEffect(() => {
    if (current) {
      _.find(ref.current, {
        id: current?.imageid?.replace('_w32', ''),
      }).scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [ref.current])

  return (
    <>
      <motion.div className='page-doc-list'>
        {tag
          ? imgs.map((d, i) => (
              <SideItemTag
                tag={tag}
                d={d}
                i={i}
                reff={ref}
                imgs={imgs}
                current={current}
                setCurrent={setCurrent}
              />
            ))
          : imgs.map((d, i) => (
              <SideItem
                d={d}
                i={i}
                reff={ref}
                imgs={imgs}
                current={current}
                setCurrent={setCurrent}
              />
            ))}
      </motion.div>
    </>
  )
}
