import { useEffect, useState, useContext, useMemo } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import {
  Container,
  Box,
  Stack,
  Modal,
  Typography,
  TextField,
  Button,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import _ from 'lodash'
import $axios from '../../tools/axiosWrapper'
import TagIcon from '@mui/icons-material/Tag'
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark'
import { Master } from '../home/master'

export const Collection = ({ user }) => {
  const theme = useTheme()
  useEffect(() => {}, [])

  return (
    <Stack
      style={{
        width: '100vw',
        minHeight: '100vh',
      }}
      alignItems={'center'}
    >
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ mt: 4 }}
      >
        <CollectionsBookmarkIcon sx={{ transform: 'scale(1.5)' }} />
        <Typography variant={'h4'}>collection</Typography>
      </Stack>
      <Master user={user} />
    </Stack>
  )
}
