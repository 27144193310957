import { useEffect, useState, useContext, useMemo } from 'react'
import {
  Container,
  Box,
  Stack,
  Modal,
  Typography,
  TextField,
  Button,
  Menu,
  MenuItem,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import _ from 'lodash'
import $axios from '../../tools/axiosWrapper'
import LinkIcon from '@mui/icons-material/Link'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useCounter } from 'usehooks-ts'

export const TagItem = ({ d }) => {
  const { count, setCount, increment, decrement, reset } = useCounter(0)

  const theme = useTheme()
  const [doc, setDoc] = useState(false)
  useEffect(() => {
    // setLoad(true)
    $axios
      .get('/doc/list/' + d.documentid)
      .then((response) => {
        setDoc(response?.data?.contents[0])
      })
      .catch((error) => {})
  }, [])

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {doc?.images?.length > 0 && (
        <Stack
          sx={{ background: theme.palette.purple[1], p: 2, borderRadius: 2 }}
        >
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Link
              className={'link'}
              to={'../doc/' + d.documentid}
              state={{ documentid: d.documentid, tagid: d.id }}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <Typography variant={'h5'}>{'#' + d.id}</Typography>
                <LinkIcon />
              </Stack>
            </Link>
          </Stack>

          <Stack sx={{ p: 2 }}>
            <Typography variant={'subtitle1'} color={theme.palette.white[3]}>
              {doc &&
                doc?.images?.length +
                  ' image' +
                  (doc?.images?.length > 1 ? 's' : '') +
                  ':'}
            </Typography>
            <Stack direction={'row'} sx={{ py: 1, overflowX: 'scroll' }}>
              {doc &&
                doc?.images
                  ?.map((d) => d.replace('_w32', '').replace('_w31', ''))
                  .map((d, i) => (
                    <motion.div
                      style={{
                        border: '1px solid ' + theme.palette.white[3],
                        margin: '4px',
                        padding: '4px',
                        color: theme.palette.white[3],
                        borderRadius: '6px',
                      }}
                    >
                      {d}
                    </motion.div>
                  ))}
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  )
}
