import { useEffect, useState, useContext, useMemo } from 'react'
import { Container, Grid, Typography, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'

const linkStyle = {
    color: '#7979f0',
}

export const RedactionPrivacy = () => {
    const theme = useTheme();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <Stack sx={{ minHeight: '100vh', py: 12 }} alignItems={'center'}>
                <Typography
                    variant='h2'
                    sx={{ fontWeight: 800, color: theme.palette.purple[5], mb: 8 }}
                >
                    REDACTION GUIDANCE – PRIVACY<br/>(from the NYU-Gizmodo effort)
                </Typography>

                <Stack sx={{ width: 700 }} spacing={6}>
                    <Stack spacing={2}>
                        <Typography variant='body1'>
                            The following guidance was constructed by a multidisciplinary research team that included 
                            Laura Edelson at New York University and Ethan Zuckerman at the University of 
                            Massachusetts in an earlier collaboration to explore methods for making the internal 
                            Facebook documents publicly accessible. This is not necessarily the same as <Link style={linkStyle} to="/redaction">the implemented guidance from FBarchive's Safety and Privacy Board within FBarchive itself.</Link>
                        </Typography>
                        <Typography variant='h4'>Facebook Papers Redaction Guidance - Privacy</Typography>
                        <Typography variant='body1'>
                            This document contains guidelines for how to redact the documents collectively referred to as
                            “The Facebook Papers” for concerns of individual privacy. Security concerns will be addressed
                            in separate guidance.
                        </Typography>
                        <Typography variant='body1'>
                            <p>In general, personal identifiers of any kind should be removed. This should include:</p>
                            <ol>
                                <li>Names (even just first names)</li>
                                <li>URLs that contain usernames or ids</li>
                                <li>
                                    Addresses or other text that would uniquely identify a small number of people or create
                                    the opportunity for misidentification
                                </li>
                                <li>
                                    Identifying images
                                    <ol>
                                        <li>Faces</li>
                                        <li>Profile images (other than text)</li>
                                        <li>Clearly visible clothing, or clothing on children</li>
                                        <li>
                                            Images that contain text or handwritten notes with any personally identifiable
                                            information including names, IP addresses, usernames and passwords, phone
                                            numbers, etc.
                                        </li>
                                        <li>Other identifying images</li>
                                    </ol>
                                </li>
                                <li>
                                    Titles that are held by a small enough number of people that they are identifiable or
                                    create the opportunity for misidentification
                                </li>
                            </ol>
                        </Typography>
                        <Typography variant='body1'>
                            However, we believe public figures should be excluded from redaction; this includes both their
                            names and images. Public figures include politicians, public/government officials, celebrities,
                            sportspeople, social media influencers, journalists and broadcast personalities, and corporate
                            executives.
                        </Typography>
                        <Typography variant='body1'>
                            Additionally, we believe a set of individuals working who are highly visible employees, officers,
                            or members of the board of Meta, should not have their names redacted. We exclude from
                            redaction both people currently in these roles and those who held them formerly. The current list
                            of people associated with Facebook that we believe should be excluded from redaction is below,
                            and may be updated in the future:
                        </Typography>
                        <Typography variant='body1'>
                            <table width="735" cellspacing="0" cellpadding="1">
                                <tbody>
                                    <tr>
                                        <td width="233" height="39">
                                            <p>Mark Zuckerberg</p>
                                        </td>
                                        <td width="494">
                                            <p>Founder, Chairman, CEO</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="39">
                                            <p>Sheryl Sandberg</p>
                                        </td>
                                        <td width="494">
                                            <p>COO</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="24">
                                            <p>Nick Clegg</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Global Affairs &amp; Communications</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="26">
                                            <p>David Wehner</p>
                                        </td>
                                        <td width="494">
                                            <p>CFO</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="28">
                                            <p>Chris Cox</p>
                                        </td>
                                        <td width="494">
                                            <p>CPO</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="26">
                                            <p>Javier Olivan</p>
                                        </td>
                                        <td width="494">
                                            <p>Chief Growth Officer</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Mike Schroepfer</p>
                                        </td>
                                        <td width="494">
                                            <p>Senior Fellow / Outgoing CTO 2021</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="28">
                                            <p>Marne Levine</p>
                                        </td>
                                        <td width="494">
                                            <p>Chief Business Officer</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="26">
                                            <p>David Fischer</p>
                                        </td>
                                        <td width="494">
                                            <p>Chief Revenue Officer</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Jennifer Newstead</p>
                                        </td>
                                        <td width="494">
                                            <p>Chief Legal Officer</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="28">
                                            <p>Lori Goler</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of People</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Maxine Williams</p>
                                        </td>
                                        <td width="494">
                                            <p>Chief Diversity Officer</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Andrew Bosworth</p>
                                        </td>
                                        <td width="494">
                                            <p>CTO from Q3 2021 / Head of Facebook Reality Labs</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="26">
                                            <p>Will Cathcart</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of WhatsApp</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Stan Chudnovsky</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Messenger</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="28">
                                            <p>Erin Egan</p>
                                        </td>
                                        <td width="494">
                                            <p>Chief Privacy Officer, Policy</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="26">
                                            <p>Naomi Gleit</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Product Management</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="28">
                                            <p>Dan Levy</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Ads and Business Products</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>David Mortenson</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Infrastructure</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="26">
                                            <p>Adam Mosseri</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Instagram</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="28">
                                            <p>Michel Protti</p>
                                        </td>
                                        <td width="494">
                                            <p>Chief Privacy Officer, Product</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="26">
                                            <p>Alex Schultz</p>
                                        </td>
                                        <td width="494">
                                            <p>CMO / VP Analytics</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="28">
                                            <p>Tom Alison</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Facebook App</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="33">
                                            <p>Susan J S Taylor</p>
                                        </td>
                                        <td width="494">
                                            <p>Chief Accounting Officer</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="50">
                                            <p>Peggy Alford</p>
                                        </td>
                                        <td width="494">
                                            <p>Board of Directors | Member of the Privacy Committee &amp; Member of the Audit &amp; Risk Oversight Committee</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="50">
                                            <p>Marc L. Andreessen</p>
                                        </td>
                                        <td width="494">
                                            <p>Board of Directors | Member of the Compensation, Nominating &amp; Governance Committee</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="48">
                                            <p>Andrew W. Houston</p>
                                        </td>
                                        <td width="494">
                                            <p>Board of Directors | Member of the Compensation, Nominating &amp; Governance Committee</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="50">
                                            <p>Nancy Killefer</p>
                                        </td>
                                        <td width="494">
                                            <p>Board of Directors | Member of the Audit &amp; Risk Oversight Committee &amp; Chair of Privacy Committee</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Robert M. Kimmitt</p>
                                        </td>
                                        <td width="494">
                                            <p>Board of Directors | Member of the Privacy Committee</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="48">
                                            <p>Peter A. Thiel</p>
                                        </td>
                                        <td width="494">
                                            <p>Board of Directors | Chair of the Compensation, Nominating &amp; Governance Committee</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="735" cellspacing="0" cellpadding="1">
                                <tbody>
                                    <tr>
                                        <td width="233" height="39">
                                            <p>Tracey T. Travis</p>
                                        </td>
                                        <td width="494">
                                            <p>Board of Directors | Chair of the Audit &amp; Risk Oversight Committee</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="35">
                                            <p>Kenneth Chenault</p>
                                        </td>
                                        <td width="494">
                                            <p>Left Facebook Board in 2020</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="30">
                                            <p>Jeffrey D. Zients</p>
                                        </td>
                                        <td width="494">
                                            <p>Left Facebook Board in 2020</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="38">
                                            <p>Dr. Susan Desmond-Hellmann</p>
                                        </td>
                                        <td width="494">
                                            <p>Left Facebook Board in 2019</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Erskine B. Bowles</p>
                                        </td>
                                        <td width="494">
                                            <p>Left Facebook Board in 2019</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Reed Hastings</p>
                                        </td>
                                        <td width="494">
                                            <p>Left Facebook Board in 2019</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Justin Osofsky</p>
                                        </td>
                                        <td width="494">
                                            <p>Instagram COO &amp; VP of Global Operations</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Max Eulenstein</p>
                                        </td>
                                        <td width="494">
                                            <p>Co-Head of Product, Instagram</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Matt Idema</p>
                                        </td>
                                        <td width="494">
                                            <p>COO, WhatsApp</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Guy Rosen</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Integrity</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Joel Kaplan</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Global Public Policy</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Jerome Pesenti</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Artificial Intelligence</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Molly Cutler</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Strategic Response Program</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Ime Archibong</p>
                                        </td>
                                        <td width="494">
                                            <p>VP New Product Experimentation</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Rob Sherman</p>
                                        </td>
                                        <td width="494">
                                            <p>VP &amp; Deputy Chief Privacy Officer for Policy</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Margaret Stewart</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Product Design</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Chris Sonderby</p>
                                        </td>
                                        <td width="494">
                                            <p>VP &amp; Deputy General Counsel</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Pavni Diwanji</p>
                                        </td>
                                        <td width="494">
                                            <p>VP of Youth Products</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>John Pinette</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Global Communications</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Jason Rubin</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Play</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Monika Bickert</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Global Policy Management</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>David Ginsberg</p>
                                        </td>
                                        <td width="494">
                                            <p>VP of Product, Choice, Competition</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Ellen Silver</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Operations</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="39">
                                            <p>Janelle Gale</p>
                                        </td>
                                        <td width="494">
                                            <p>VP HR</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="735" cellspacing="0" cellpadding="1">
                                <tbody>
                                    <tr>
                                        <td width="233" height="39">
                                            <p>Arun Chandra</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Scaled Operations</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Mark Rabkin</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Oculus</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Alex Himel</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Local</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Sibyl Goldman</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Global Entertainment Partnerships</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Campbell Brown</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Global News Partnerships</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>John Hegeman</p>
                                        </td>
                                        <td width="494">
                                            <p>VP News Feed &amp; Stories</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Kevin Martin</p>
                                        </td>
                                        <td width="494">
                                            <p>VP US Public Policy / Head of Global Economic Policy</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Dan Reed</p>
                                        </td>
                                        <td width="494">
                                            <p>VP, Global Media, Sports &amp; Equity Partnerships</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Roy L. Austin, Jr.</p>
                                        </td>
                                        <td width="494">
                                            <p>VP and Deputy General Counsel, Civil Rights</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Neil Potts</p>
                                        </td>
                                        <td width="494">
                                            <p>VP, Trust and Safety</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Simon Milner</p>
                                        </td>
                                        <td width="494">
                                            <p>VP, Public Policy Asia-Pacific</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Markus Reinisch</p>
                                        </td>
                                        <td width="494">
                                            <p>VP, Public Policy, EMEA</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Nicola Mendelsohn</p>
                                        </td>
                                        <td width="494">
                                            <p>VP, EMEA</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Ajit Mohan</p>
                                        </td>
                                        <td width="494">
                                            <p>VP &amp; MD, India</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Richard Allan</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Policy Solutions</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Stephane Kasriel</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Facebook Financial</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Vishal Shah</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Product, metaverse</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Pratiti Raychoudhury</p>
                                        </td>
                                        <td width="494">
                                            <p>VP, Head of Research</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Anna Benckert</p>
                                        </td>
                                        <td width="494">
                                            <p>VP and Associate General Counsel, EMEA</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Will Easton</p>
                                        </td>
                                        <td width="494">
                                            <p>Managing Director &amp; VP, Australia and NZ.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Steve Satterfield</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Privacy and Public Policy</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>David Agranovich</p>
                                        </td>
                                        <td width="494">
                                            <p>Director, Threat Disruption</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Antigone Davis</p>
                                        </td>
                                        <td width="494">
                                            <p>Director, Global Head of Safety</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="39">
                                            <p>Lena Pietsch</p>
                                        </td>
                                        <td width="494">
                                            <p>Director, Policy Communications</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="735" cellspacing="0" cellpadding="1">
                                <tbody>
                                    <tr>
                                        <td width="233" height="39">
                                            <p>Miranda Sissons</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Human Rights</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Emily Vacher</p>
                                        </td>
                                        <td width="494">
                                            <p>Director, Trust &amp; Safety</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Aura Salla</p>
                                        </td>
                                        <td width="494">
                                            <p>Public Policy Director, Head of EU Affairs</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Brent Harris</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Governance and Global Affairs</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Jay Sullivan</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Product Management, Messenger Privacy and Safety</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Meredith Carden</p>
                                        </td>
                                        <td width="494">
                                            <p>Director, Integrity &amp; Risk Response, News</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Kaitlin Sullivan</p>
                                        </td>
                                        <td width="494">
                                            <p>Director, Content Policy</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Clair Deevy</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Public Policy, WhatsApp</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Jennifer Broxmeyer</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Governance, Global Strategic Initiatives</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Kevin Chan</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Policy, Canada</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Rosa Birch</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Strategic Response</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Chris Yiu</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Public Policy for Northern Europe</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Joelle Pineau</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Facebook Artificial Intelligence Research Labs</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Sally Aldous</p>
                                        </td>
                                        <td width="494">
                                            <p>Communications Director</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Joaquin Qui&ntilde;onero Candela</p>
                                        </td>
                                        <td width="494">
                                            <p>Director, Applied Machine Learning</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Christen Dubois</p>
                                        </td>
                                        <td width="494">
                                            <p>Director and Associate General Counsel, Litigation</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Mia Garlick</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Public Policy, Australia, New Zealand &amp; Pacific Islands</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Rafael Frankel</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Public Policy, Southeast Asia, Emerging Markets</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Gail Kent</p>
                                        </td>
                                        <td width="494">
                                            <p>Messenger Policy Director</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Jessica Romero</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Platform Enforcement and Litigation</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Srinivas Narayanan</p>
                                        </td>
                                        <td width="494">
                                            <p>Director, Applied Research, Facebook AI</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Nathaniel Gleicher</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Cybersecurity Policy</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Mike Dvilyanski</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Cyber Espionage Investigations</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="39">
                                            <p>Guido Buelow</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Facebook News Partnerships, EMEA</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="735" cellspacing="0" cellpadding="1">
                                <tbody>
                                    <tr>
                                        <td width="233" height="39">
                                            <p>Rebecca Stimson</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of UK Public Policy</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Kang-Xing Jin</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Health</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Mercy Ndegwa</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Public Policy East &amp; Horn of Africa</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Anna Stepanov</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Facebook App Integrity</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Cindy Southworth</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Women's Safety</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Robert Pepper</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Global Connectivity Policy and Planning</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Freddy Abnousi, MD</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Healthcare Research</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Fatima Saliu</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of International Marketing at Facebook</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Tessa Lyons</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of News Feed Integrity</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="48">
                                            <p>Yvonne Cunnane</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Data Protection and Privacy and Associated General Counsel</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Nawab Osman</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Counterterrorism &amp; Dangerous Organizations (APAC)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Collin Greene</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Product Security</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Nathan White</p>
                                        </td>
                                        <td width="494">
                                            <p>Privacy Policy Manager, Facebook Reality Labs</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Akua Gyekye</p>
                                        </td>
                                        <td width="494">
                                            <p>Public Policy Manager, Africa</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Laura McGorman</p>
                                        </td>
                                        <td width="494">
                                            <p>Policy Lead, Data for Good</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Оlga Belogolova</p>
                                        </td>
                                        <td width="494">
                                            <p>Policy Lead, Information Operations</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Karuna Nain</p>
                                        </td>
                                        <td width="494">
                                            <p>Global Safety Policy Lead</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Diego Bassante</p>
                                        </td>
                                        <td width="494">
                                            <p>Economic Policy Lead, LatAm</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Rachel Curran</p>
                                        </td>
                                        <td width="494">
                                            <p>Policy Manager</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Mike Clark</p>
                                        </td>
                                        <td width="494">
                                            <p>Product Management Director</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Andy Stone</p>
                                        </td>
                                        <td width="494">
                                            <p>Communications</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Tal Hassner</p>
                                        </td>
                                        <td width="494">
                                            <p>Applied Research Lead, Facebook AI</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="39">
                                            <p>Yann LeCun</p>
                                        </td>
                                        <td width="494">
                                            <p>Chief Artificial Intelligence Scientist</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="735" cellspacing="0" cellpadding="1">
                                <tbody>
                                    <tr>
                                        <td width="233" height="39">
                                            <p>Kevin Systrom</p>
                                        </td>
                                        <td width="494">
                                            <p>Founder, Instagram</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Mike Krieger</p>
                                        </td>
                                        <td width="494">
                                            <p>Founder, Instagram</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Brian Acton</p>
                                        </td>
                                        <td width="494">
                                            <p>Founder, WhatsApp</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Jan Koum</p>
                                        </td>
                                        <td width="494">
                                            <p>Founder, WhatsApp, previously on Facebook Board</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Alex Stamos</p>
                                        </td>
                                        <td width="494">
                                            <p>Chief Security Officer</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Jas Athwal</p>
                                        </td>
                                        <td width="494">
                                            <p>Chief Accounting Officer</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Antonio Lucio</p>
                                        </td>
                                        <td width="494">
                                            <p>Chief Marketing Officer</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Colin Stretch</p>
                                        </td>
                                        <td width="494">
                                            <p>General Counsel</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Rob Leathern</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of business integrity / Director of Product Management</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="31">
                                            <p>Brian Fishman</p>
                                        </td>
                                        <td width="494">
                                            <p>Director, Counterterrorism and Dangerous Organizations</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="31">
                                            <p>David Marcus</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Facebook Financial</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="26">
                                            <p>Chris Daniels</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of WhatsApp</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="30">
                                            <p>Radha Iyengar</p>
                                        </td>
                                        <td width="494">
                                            <p>Global Head of Policy Analysis</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="30">
                                            <p>Ruben Hattari</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Public Policy, Indonesia</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="26">
                                            <p>Katie Harbath</p>
                                        </td>
                                        <td width="494">
                                            <p>Director, Global Politics and Government Outreach</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Molly Jackman</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Global Policy Research and Data for Good</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>MIke Booth</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Product Management, Facebook Spaces</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="50">
                                            <p>Erin Saltman</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Counterterrorism and Dangerous Organizations Policy for Europe, the Middle East and Africa</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="26">
                                            <p>Rachel Franklin</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Social VR</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="28">
                                            <p>Jennifer Dulski</p>
                                        </td>
                                        <td width="494">
                                            <p>Head of Groups &amp; Community</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="33">
                                            <p>Rob Goldman</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Advertising</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="31">
                                            <p>Fidji Simo</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Facebook app</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="31">
                                            <p>Elliot J. Schrage</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Global Communications</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Caryn Marooney</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Global Communications</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Rachel Whetstone</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Communications for WhatsApp, Instagram and Messenger</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="39">
                                            <p>Jay Parikh</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Engineering</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="735" cellspacing="0" cellpadding="1">
                                <tbody>
                                    <tr>
                                        <td width="233" height="39">
                                            <p>Kevin Weil</p>
                                        </td>
                                        <td width="494">
                                            <p>VP of Product Instagram</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Carolyn Everson</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Global Business Group, Head of Media Strategy &amp; Advertising</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Konstantinos Papamiltiadis</p>
                                        </td>
                                        <td width="494">
                                            <p>VP of Platform Partnerships</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Richard Allan</p>
                                        </td>
                                        <td width="494">
                                            <p>VP of Policy Solutions</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Asha Sharma</p>
                                        </td>
                                        <td width="494">
                                            <p>VP of Product, Private Communications</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Ashlie Beringer</p>
                                        </td>
                                        <td width="494">
                                            <p>VP and Deputy General Counsel</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Paul Grewal</p>
                                        </td>
                                        <td width="494">
                                            <p>VP and Deputy General Counsel</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Pedro Canahuati</p>
                                        </td>
                                        <td width="494">
                                            <p>VP, Security and Privacy Engineering</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Deborah Liu</p>
                                        </td>
                                        <td width="494">
                                            <p>VP Marketplace &amp; Commerce</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Matt Perault</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Public Policy</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>David Baser</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Product Management (Facebook privacy &amp; data use)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Hema Budaraju</p>
                                        </td>
                                        <td width="494">
                                            <p>Product Director of Health</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Fay Johnson</p>
                                        </td>
                                        <td width="494">
                                            <p>Product Lead, Integrity Transparency &amp; Oversight</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Morgan Brown</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Product Management</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Amit Fulay</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Product Management, News Feed</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Doug Frisbie</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Global Customer Marketing, North America</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Brent Ayrey</p>
                                        </td>
                                        <td width="494">
                                            <p>Product Director, VR</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Ameet Ranadive</p>
                                        </td>
                                        <td width="494">
                                            <p>Product Management Director at Instagram</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="41">
                                            <p>Samidh Chakrabarti</p>
                                        </td>
                                        <td width="494">
                                            <p>Director of Product Management, Civic Engagement</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="26">
                                            <p>Sophie Zhang</p>
                                        </td>
                                        <td width="494">
                                            <p>Data Scientist / Fake Engagement</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="35">
                                            <p>Ya&euml;l Eisenstat</p>
                                        </td>
                                        <td width="494">
                                            <p>Global Head of Elections Integrity</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="233" height="26">
                                            <p>Ifeoma Ozoma</p>
                                        </td>
                                        <td width="494">
                                            <p>Policy Programs</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}