import { PanelResizeHandle } from 'react-resizable-panels'
import { useTheme } from '@mui/material/styles'

export const ResizeHandle = ({ view }) => {
  const theme = useTheme()
  return (
    <PanelResizeHandle>
      {!view ? (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            background: theme.palette.purple[4],
            opacity: 0.7,
            width: 8,
            padding: '2px',
            margin: '0px 14px',
          }}
        >
          <svg viewBox='0 0 4 36' height={36}>
            <path
              stroke='white'
              strokeWidth={4}
              style={{ strokeLinecap: 'round' }}
              d={'M 2 2 L 2 34'}
            />
          </svg>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            background: theme.palette.purple[4],
            opacity: 0.7,
            height: 8,
            padding: '2px',
            margin: '14px 0px',
          }}
        >
          <svg viewBox='0 0 36 4' width={36}>
            <path
              stroke='white'
              strokeWidth={4}
              style={{ strokeLinecap: 'round' }}
              d={'M 2 2 L 34 2'}
            />
          </svg>
        </div>
      )}
    </PanelResizeHandle>
  )
}
