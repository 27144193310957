import { useEffect, useState, useContext, useRef } from 'react'
import { Outlet, Link, useLocation } from 'react-router-dom'
import { Box, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useTheme } from '@mui/material/styles'
import { Button, CircularProgress } from '@mui/material'
import { Foot } from './foot'
import $axios from '../../tools/axiosWrapper'
import './head.css'
import { Drawerr } from './drawer'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
export const Head = ({ user, setSearch, setResults }) => {
  const location = useLocation()
  const theme = useTheme()
  const [drawer, setDrawer] = useState(false)
  return (
    <>
      <Drawerr user={user} drawer={drawer} setDrawer={setDrawer} />

      <header style={{ background: theme.palette.purple[0] }}>
        <Stack
          direction='row'
          justifyContent={'space-between'}
          alignItems={'center'}
          style={{ height: '100%' }}
        >
          <Stack
            direction='row'
            alignItems={'center'}
            sx={{ color: theme.palette.white[4] }}
          >
            <Link to={'/'}>
              <motion.div
                className='logo'
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  setSearch('')
                  setResults(null)
                }}
              >
                <motion.svg
                  alt='fb archive logo'
                  viewBox='0 0 64 50'
                  width='40'
                  style={{
                    strokeWidth: 4,
                    stroke: theme.palette.white[3],
                  }}
                >
                  <motion.line x1='9.56' y1='20.68' x2='47.12' y2='20.68' />
                  <motion.line x1='16' y1='32.68' x2='60' y2='32.68' />
                  <motion.line x1='7.79' y1='44.68' x2='54.71' y2='44.68' />
                  <motion.line x1='20.37' y1='9.04' x2='54.71' y2='8.68' />
                </motion.svg>
                <motion.div
                  style={{
                    color: theme.palette.white[3],
                  }}
                >
                  fbarchive
                </motion.div>
              </motion.div>
            </Link>
            {user && (
              <>
                <KeyboardArrowRightIcon sx={{ fontSize: '30px' }} />
                <Typography>{user?.email}</Typography>
              </>
            )}
          </Stack>
          <Box
            sx={{
              mr: '20px',
              height: theme.spacing(7),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {user ? (
              <>
                <motion.svg
                  alt='account menu'
                  className='account'
                  viewBox='0 0 64 64'
                  width='40'
                  onClick={() => setDrawer(true)}
                  whileTap={{ scale: 0.95 }}
                >
                  <AccountCircleIcon
                    sx={{ fontSize: '35px', color: theme.palette.white[4] }}
                  />
                </motion.svg>
              </>
            ) : (
              <></>
              // <Button
              //   sx={{ color: theme.palette.white[3], fontWeight: 600, pr: 1 }}
              //   onClick={() => {
              //     $axios.get('/auth/login').then((response) => {
              //       window.location.href = response.data.login_url
              //     })
              //   }}
              // >
              //   login
              // </Button>
            )}
          </Box>
        </Stack>
      </header>
      <Outlet />
      {!location.pathname.includes('doc') && <Foot />}
    </>
  )
}
