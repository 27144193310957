import { useEffect, useState, useContext, useRef } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { Typography, Box, Stack } from '@mui/material'
import { motion } from 'framer-motion'
import { useTheme } from '@mui/material/styles'
import { ReactComponent as PITL } from './PITL.svg'
import SHOR from './SHOR.png'
import DPL from './DPL.png'

export const Foot = () => {
  const theme = useTheme()

  const pages = ['about', 'history', 'tutorial', 'faq', 'explorations']

  return (
    <footer style={{ background: theme.palette.purple[1] }}>
      <Box
        sx={{
          width: '100%',
          height: 2,
          background: theme.palette.white[4],
        }}
      ></Box>
      <Stack
        sx={{
          height: 50,
          width: '100%',
          px: '20vw',
          pt: 6,
          pb: 12,
        }}
        direction='row'
        justifyContent={'space-between'}
      >
        {pages.map((d, i) => (
          <Link to={'/' + d}>
            <Typography
              sx={{
                color: theme.palette.white[1],
                fontSize: 22,
                fontWeight: 800,
                textTransform: 'uppercase',
                cursor: 'pointer',
              }}
            >
              {d}
            </Typography>
          </Link>
        ))}
      </Stack>

      <motion.div
        className='logos'
        style={{ background: theme.palette.purple[1] }}
      >
        <a href='https://techlab.org/' target='_blank' rel='noreferrer'>
          <PITL width={130} />
        </a>
        <a
          href='https://shorensteincenter.org/'
          target='_blank'
          rel='noreferrer'
        >
          <img width={190} src={SHOR} alt='Shorenstein Center logo' />
        </a>
        <a href='https://dataprivacylab.org/' target='_blank' rel='noreferrer'>
          <img
            width={190}
            src={DPL}
            alt='Data Privacy Lab logo'
            style={{ filter: 'invert(1)' }}
          />
        </a>
      </motion.div>
    </footer>
  )
}
