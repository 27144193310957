import { useEffect, useState, useContext, useMemo } from 'react'

import { Container, Grid, Typography, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'


import image1 from './howto_images/howto1.png';
import image2 from './howto_images/howto2.png';
import image3 from './howto_images/howto3.png';
import image4 from './howto_images/howto4.png';
import image5 from './howto_images/howto5.png';
import image6 from './howto_images/howto6.png';

const imageStyle = {
    width: '100%'
}

export const HowTo = () => {
    const theme = useTheme()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Stack sx={{ minHeight: '100vh', py: 12 }} alignItems={'center'}>
                <Typography
                    variant='h2'
                    sx={{ fontWeight: 800, color: theme.palette.purple[5], mb: 8 }}
                >
                    HOW TO USE
                </Typography>

                <Stack sx={{ width: 700 }} spacing={6}>
                    <Stack spacing={2}>
                        <Typography
                            variant='body1'
                            sx={{
                                color: theme.palette.white[0],
                                fontWeight: 800,
                                fontSize: 18,
                            }}
                        >
                            Search Function
                        </Typography>
                        <Typography variant='body1'>
                            The search function is a traditional Boolean search where users can enter queries connecting individual keywords or phrases using the operators “AND”, “OR”, “NOT” to refine, broaden, or limit their search. Operators must be capitalized and exact phrases should be enclosed in quotation marks (e.g. “mental health”). The query searches all image text and document titles for keywords, returning a list of images ordered alphabetically that includes image number, text excerpt, document title and document number, and a link to view each image. Note that because the search includes document titles, images may be returned that do not include the keywords in the image text, but do include them in the document title.
                        </Typography>
                    </Stack>
                    <Stack spacing={2}>
                        <Typography
                            variant='body1'
                            sx={{
                                color: theme.palette.white[0],
                                fontWeight: 800,
                                fontSize: 18,
                            }}
                        >
                            Bookmarking Documents
                        </Typography>
                        <Typography variant='body1'>
                            In order to save a document for future reference, click the bookmark icon to the left of the document title. This will save the entire document to users’ personal bookmarks, which can be accessed by clicking the user icon at the top right of the homepage, under the “Saved” link.
                        </Typography>
                        <img
                            src={image1}
                            style={imageStyle}
                            alt='Bookmarking documents'
                        />
                    </Stack>
                    <Stack spacing={2}>
                        <Typography
                            variant='body1'
                            sx={{
                                color: theme.palette.white[0],
                                fontWeight: 800,
                                fontSize: 18,
                            }}
                        >
                            Tagging Documents
                        </Typography>
                        <Typography variant='body1'>
                            The tagging functionality allows users to curate their own collection of images across different documents according to a particular theme or topic. To add a tag to an image, type the name of the tag into the small search bar above the image. Tagged collections are accessed by clicking the user icon at the top right of the homepage, under the “Tags” link.
                        </Typography>
                        <img
                            src={image2}
                            style={imageStyle}
                            alt='Tagging documents'
                        />
                    </Stack>
                    <Stack spacing={2}>
                        <Typography
                            variant='body1'
                            sx={{
                                color: theme.palette.white[0],
                                fontWeight: 800,
                                fontSize: 18,
                            }}
                        >
                            Glossary
                        </Typography>
                        <Typography variant='body1'>
                            We have embedded a glossary into the image view that includes many of Meta’s corporate acronyms, basic statistical concepts and quantities, and other terms to aid comprehension. The glossary is accessed by clicking the book icon above the image, next to the small search bar; the glossary will pop up as an inset window in the image view.
                        </Typography>
                        <img
                            src={image3}
                            style={imageStyle}
                            alt='Glossary button'
                        />
                        <img
                            src={image4}
                            style={imageStyle}
                            alt='Glossary'
                        />
                    </Stack>
                    <Stack spacing={2}>
                        <Typography
                            variant='body1'
                            sx={{
                                color: theme.palette.white[0],
                                fontWeight: 800,
                                fontSize: 18,
                            }}
                        >
                            Citations
                        </Typography>
                        <Typography variant='body1'>
                            Dr. Amelia Acker created bespoke citations for all images and documents. Image citations are listed at the bottom of the image view, easily copied and pasted by clicking the “Copy” icon at the far right of the citation. Document citations are listed below the document title and can be copied in the same way, using the “Copy” icon to the right of the citation. 
                        </Typography>
                        <img
                            src={image5}
                            style={imageStyle}
                            alt='Citation button'
                        />
                        <img
                            src={image6}
                            style={imageStyle}
                            alt='Citation button'
                        />
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}
