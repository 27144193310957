import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: { default: '#131316', paper: '#212126' },
    primary: { main: '#FFF', light: '#F6F3FD', dark: '#D3BFFF' },
    purple: ['#131316', '#212126', '#26262F', '#34343F', '#49495A', '#B6B6D1'],
    white: ['#FFF', '#F4F4F6', '#DDDDE3', '#C7C7D1', '#9A9AAC'],
    random: ['#9BDFFF'],
  },
  typography: {
    fontFamily: ['Roboto Mono'],
  },
})

export default theme
