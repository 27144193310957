import React, { useEffect, useState, useContext, useMemo } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { TextField, Box, Stack, Autocomplete } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import _, { set } from 'lodash'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { ResizeHandle } from './handle'
import { FormStart } from './formstart'
import $axios from '../../tools/axiosWrapper'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import LinearProgress from '@mui/material/LinearProgress'
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'
import './page.css'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { Side } from './side'
import { Head } from './head'
import { Tags } from './tagsbar'
import { Glossary } from './glossary'
import { Citation } from './citation'

const INIT = { images: [], documentid: '' }
const Doc = ({ user }) => {
  const theme = useTheme()
  const [doc, setDoc] = useState(INIT)
  const [done, setDone] = useState(0)
  const [view, setView] = useState(true)
  const [open, setOpen] = useState(false)
  const [glossary, setGlossary] = useState(false)
  const [current, setCurrent] = useState(false)
  const [extracted, setExtracted] = useState(false)

  const [docid, setDocid] = useState('')

  const [load, setLoad] = useState(true)

  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [])

  window.onpopstate = () => {
    navigate('/', { state: { data: location.state?.results } })
  }

  useEffect(() => {
    setCurrent(_.find(doc.images, { imageid: location.state?.img }))
  }, [doc])

  useEffect(() => {
    setLoad(true)
    let path = location.pathname.replace('/doc/', '')
    let isPublic = /^\d+$/.test(path)
    let docid1 = location.pathname
      .replace('/doc/', '')
      .replace('w32', '')
      .concat(isPublic ? '' : extracted ? 'w31' : 'w32')
      .replace('w35w32', 'w35')
      .replace('w35w31', 'w35')

    setDocid(docid1)
    $axios
      .get('/doc/all/' + docid1)
      .then((response) => {
        setDoc(response.data)
      })
      .catch((er) => console.log(er))
      .finally(() => setLoad(false))
  }, [extracted])

  return (
    <>
      {load ? (
        <LinearProgress />
      ) : (
        <Box sx={{ height: '4px', width: '100vw' }}></Box>
      )}
      <TransformWrapper>
        {({ zoomIn, zoomOut, resetTransform, ...reset }) => (
          <div className='page'>
            <div
              className='page-doc'
              style={{ background: theme.palette.purple[0] }}
            >
              <Head user={user} doc={doc} docid={docid} />
              <div className='page-doc-desk'>
                <Side
                  tag={doc.tag || false}
                  imgs={doc.images}
                  current={current}
                  setCurrent={setCurrent}
                />
                <div className='page-doc-view'>
                  <Stack
                    direction={'row'}
                    sx={{
                      width: 'calc(100% - 10px)',
                      height: 40,
                      background: theme.palette.purple[1],
                      borderRadius: '10px',
                      mb: 2,
                      px: 2,
                      pt: '2px',
                      overflowX: 'hidden',
                      overflowY: 'hidden',
                    }}
                  >
                    <Stack
                      sx={{
                        width: '100%',
                        height: '40px',
                      }}
                      alignItems={'center'}
                      direction={'row'}
                      spacing={2}
                    >
                      {[
                        {
                          icon: (
                            <svg
                              width={44}
                              viewBox='0 0 128 128'
                              onClick={() => setView(!view)}
                              style={{ cursor: 'pointer', padding: '10px' }}
                            >
                              <g
                                style={{
                                  fill: 'none',
                                  stroke: 'white',
                                  strokeLinecap: 'round',
                                  strokeLineJoin: 'round',
                                  strokeWidth: '10px',
                                }}
                              >
                                <rect
                                  x='9'
                                  y='19'
                                  width='110'
                                  height='90'
                                  rx='10'
                                />
                                {view ? (
                                  <line x1='119' y1='64' x2='9' y2='64' />
                                ) : (
                                  <line x1='64' y1='19' x2='64' y2='109' />
                                )}
                              </g>
                            </svg>
                          ),
                          fn: () => setView(!view),
                        },
                        { icon: <ZoomInIcon />, fn: () => zoomIn() },
                        { icon: <ZoomOutIcon />, fn: () => zoomOut() },
                        {
                          icon: <FullscreenExitIcon />,
                          fn: () => resetTransform(),
                        },
                        {
                          icon: <MenuBookIcon />,
                          fn: () => setGlossary(!glossary),
                        },
                      ].map((d, i) => (
                        <>
                          <motion.div
                            style={{ padding: '2px', cursor: 'pointer' }}
                            whileTap={{ scale: 0.95 }}
                            transition={{ duration: 0.1 }}
                            onClick={d.fn}
                          >
                            {d.icon}
                          </motion.div>
                        </>
                      ))}
                      <div
                        style={{
                          height: '22px',
                          width: 2,
                          // backgroundColor: theme.palette.purple[4],
                          margin: '0px 4px',
                        }}
                      />

                      <Tags
                        user={user}
                        current={current?.imageid?.split('_')[0]}
                        doc={doc}
                      />
                    </Stack>
                    <div
                      style={{
                        height: '60%',
                        width: '2px',
                        margin: 'auto 20px',
                        background: theme.palette.white[4],
                      }}
                    ></div>
                    <Stack direction={'row'} spacing={3}>
                      {[
                        {
                          name: 'full',
                          fn: () => setExtracted(false),
                          color: !extracted
                            ? theme.palette.white[0]
                            : theme.palette.purple[4],
                        },
                        {
                          name: 'extracted',
                          fn: () => setExtracted(true),
                          color: extracted
                            ? theme.palette.white[0]
                            : theme.palette.purple[4],
                        },
                      ].map((d, i) => (
                        <motion.div
                          onClick={d.fn}
                          style={{ cursor: 'pointer' }}
                        >
                          <Stack
                            sx={{
                              height: '100%',
                              fontSize: '14px',
                              pb: '2px',
                              fontWeight: 800,
                              color: d.color,
                              textTransform: 'uppercase',
                            }}
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            {d.name}
                          </Stack>
                        </motion.div>
                      ))}
                    </Stack>
                  </Stack>
                  <div
                    className='page-doc-viewer'
                    style={{
                      width: '100%',
                      height: 'calc(100% - 64px)',
                    }}
                  >
                    <PanelGroup direction={view ? 'vertical' : 'horizontal'}>
                      <Panel minSize={25} defaultSize={60}>
                        <div
                          style={{
                            height: '100%',
                            width: '100%',
                          }}
                        >
                          <TransformComponent>
                            <img
                              onDragStart={(event) => event.preventDefault()}
                              src={current ? current.imgurl : ''}
                              style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'contain',
                                display: 'block',
                                margin: 'auto',
                                userSelect: 'none',
                              }}
                            />
                          </TransformComponent>
                        </div>
                      </Panel>
                      <ResizeHandle view={view} />
                      <Panel minSize={20} defaultSize={40}>
                        <div
                          className='textarea'
                          style={{
                            fontSize: 17,
                            background: '#1C1C21',
                            height: 'calc(100% - 68px)',
                            width: '100%',
                          }}
                        >
                          {current?.text}
                        </div>
                        <Citation current={current} />
                      </Panel>
                    </PanelGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </TransformWrapper>

      {open && <FormStart open={open} setOpen={setOpen} current={current} />}
      {glossary && <Glossary glossary={glossary} setGlossary={setGlossary} />}
    </>
  )
}

export default Doc
