import { useNavigate } from 'react-router'
import { useTheme } from '@mui/material/styles'
import { Box, Button } from '@mui/material'
import { useLocalStorage } from 'usehooks-ts'
import { useEffect } from 'react'

export const Terms = ({}) => {
  const [agreed, setAgreed] = useLocalStorage('fbarchive-agreed')

  const theme = useTheme()
  let navigate = useNavigate()
  const style = {
    mx: '10vw',
    my: '10vh',
    width: '80vw',
    height: '80vh',
    bgcolor: theme.palette.purple[2],
    boxShadow: 18,
    outline: 'none',
    p: 8,
    overflowY: 'scroll',
  }

  useEffect(() => {
    agreed && navigate('/')
  }, [])

  return (
    <Box sx={style}>
      <Box sx={{ fontSize: 30, fontWeight: 600 }}>
        fbarchive.org TERMS OF SERVICE
      </Box>
      <Box
        sx={{
          mt: 2,
          mb: 6,
          width: '90%',
          height: 3,
          background: theme.palette.white[1],
        }}
      ></Box>
      <div>
        <p>
          Welcome to fbarchive.org, an online resource offered by the Data
          Privacy Lab and Public Interest Technology Lab at Harvard University
          (&ldquo;Harvard&rdquo;). The fbarchive resource
          (&ldquo;FBarchive&rdquo;) includes all pages within the fbarchive.org
          (the &ldquo;Website&rdquo;); all pages displayed by the FBarchive app
          (the &ldquo;App&rdquo;) within the MyDataCan Platform (the &ldquo;MDC
          Platform&rdquo;); the MDC Platform, to the extent you use it to access
          FBarchive; and all content, services, features, and affordances
          provided by or through the Website and/or App.
        </p>

        <p>
          By accessing content on the Website, creating a User Account, or
          subscribing to or using the App, you accept and agree to be bound by
          the following Terms of Service:
        </p>

        <p>
          1. FBarchive. The purpose of FBarchive (the &ldquo;Purpose&rdquo;) is
          to create a secure and privacy-principled ecosystem where journalists,
          researchers, and members of the public can access, review, comment on,
          and discuss data, communications, content and other material (the
          &ldquo;Data&rdquo;) that relate to the business and operations of
          Meta, Inc. and the social media and other platforms and services Meta
          provides.
        </p>

        <p>
          2. Appropriate Use/ Conduct. You agree that you are responsible for
          your own use of FBarchive, and that you will use FBarchive in
          compliance with these Terms of Service and all applicable local,
          state, national and international laws, rules and regulations,
          including privacy and copyright laws, any laws regarding the
          transmission of technical data exported from your country of
          residence, and all United States export control laws.
        </p>

        <p>You further agree to the following FBarchive Core Principles:</p>

        <p>
          &#9744; You will access and use FBarchive only consistently with the
          Purpose.
        </p>

        <p>
          &#9744; Except that you may disclose reasonable and limited portions
          of the Data outside the platform in works of scholarship, commentary,
          and journalism, you will not (1) download, remove, or copy Data from
          FBarchive; (2) publish or post FBarchive Data on any other platform or
          in any other medium; or (3) disclose Data from FBarchive to any person
          outside of FBarchive.
        </p>

        <p>
          &#9744; You will not identify or seek to identify any person who is an
          author or subject of, and not already identified in, the Data.
        </p>

        <p>&#9744; You will access and use FBarchive always in good faith.</p>

        <p>You further agree that you will not:</p>

        <p>
          &middot; Impersonate any other person, including by establishing a
          User Account with a name or identity that is not your own or by using
          a User Account that does not belong to you;
        </p>

        <p>
          &middot; Provide false, misleading, or inaccurate information to
          FBarchive;
        </p>

        <p>&middot; Reverse-engineer FBarchive or any part of FBarchive;</p>

        <p>
          &middot; Use FBarchive in any manner that could reasonably be expected
          to damage, disable, overburden, interfere with, or disrupt any part of
          FBarchive, the MDC
        </p>

        <p>
          Platform, or the computer equipment or network(s) connected to either
          FBarchive or the MDC Platform;
        </p>

        <p>
          &middot; Attempt to gain unauthorized access to FBarchive, the MDC
          Platform, any MDC Platform or FBarchive user accounts, or the computer
          equipment or networks connected to FBarchive or MDC Platform via
          hacking, password mining, or any other means;
        </p>

        <p>
          &middot; Obtain or attempt to obtain any materials or information on
          or via FBarchive or the MDC Platform, if such materials or information
          are not expressly made available to you through FBarchive or the MDC
          Platform;
        </p>

        <p>&middot; Copy any portion of FBarchive;</p>

        <p>
          &middot; Use any high-volume, automated, or electronic means to access
          FBarchive (including without limitation robots, spiders or scripts);
        </p>

        <p>
          &middot; Frame FBarchive&rsquo;s Website or App, place pop-up windows
          over its pages, or otherwise affect the display of its pages; or
        </p>

        <p>
          &middot; Force headers or otherwise manipulate identifiers in order to
          disguise the origin of any communication transmitted through
          FBarchive.
        </p>

        <p>
          Your ability to access and use FBarchive is conditioned on your full
          compliance with these Terms of Service. You understand and agree that
          Harvard may suspend or terminate your access to FBarchive for
          noncompliance with these Terms of Service or for any other reason or
          for no reason.
        </p>

        <p>
          3. User-Generated Content. FBarchive provides features by which users
          can post annotations, comments, and other information
          (&ldquo;User-Generated Content&rdquo;). If you choose to post
          User-Generated Content, you will not:
        </p>

        <p>
          &middot; Make comments that are abusive, threatening, knowingly false,
          or unlawful.
        </p>

        <p>&middot; Use foul language or engage in personal attacks.</p>

        <p>
          &middot; Post content that infringes a copyright, trademark, or patent
          right, trade secret, or other legal right of any person.
        </p>

        <p>
          By submitting User-Generated Content for publication on FBarchive, you
          grant Harvard a perpetual, irrevocable, worldwide, transferable,
          royalty-free, and non-exclusive license to reproduce, publicly
          perform, publicly display, and distribute said content on FBarchive.
          You understand and agree that Harvard may monitor User-Generated
          Content on FBarchive and reserves the right to modify or remove any of
          said content at its discretion, without notice, and for any reason.
          You understand that Harvard assumes no responsibility for monitoring,
          modifying, or removing, or failing to monitor, modify, or remove, any
          User-Generated Content. You further understand and agree that the
          views and opinions expressed by other users on FBarchive are theirs
          alone and should not be ascribed to Harvard. Content created by third
          parties is the sole responsibility of the third parties, and its
          accuracy and completeness are not endorsed or guaranteed by Harvard.
          Harvard will have no liability in the event any User-Generated Content
          you post violates third-party rights or is used or disclosed by others
          in violation of these Terms of Service. You understand that
          User-Generated Content that you post may not remain confidential, so
          you should not post anything that you do not want made public,
        </p>

        <p>
          including anything that may embarrass or expose you or your employer
          to a risk of litigation.
        </p>

        <p>
          4. DISCLAIMER OF WARRANTY. TO THE FULLEST EXTENT PERMITTED BY
          APPLICABLE LAW, FBarchive AND ANY INFORMATION, PRODUCTS, OR SERVICES
          THEREIN OR OBTAINED THEREBY ARE PROVIDED &ldquo;AS IS&rdquo; WITHOUT
          WARRANTY OF ANY KIND (EXPRESS, IMPLIED, OR OTHERWISE), INCLUDING,
          WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
          FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. WE DO NOT WARRANT THAT
          FBarchive WILL OPERATE IN AN UNINTERRUPTED OR ERROR-FREE MANNER OR
          THAT FBarchive IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WITHOUT
          LIMITING THE FOREGOING, WE DO NOT WARRANT: (A) THAT FBarchive WILL
          MEET YOUR REQUIREMENTS OR EXPECTATIONS OR ACHIEVE THE INTENDED
          PURPOSES; (B) THAT FBarchive WILL NOT EXPERIENCE OUTAGES OR OTHERWISE
          WILL BE UNINTERRUPTED, TIMELY, OR SECURE; (C) THAT THE INFORMATION OR
          SERVICE OBTAINED THROUGH OR FROM FBarchive WILL BE ACCURATE, COMPLETE,
          CURRENT, ERROR-FREE, OR RELIABLE; OR (D) THAT ANY DEFECTS IN OR ON
          FBarchive WILL BE CORRECTED. WE MAKE NO REPRESENTATION OR WARRANTY
          REGARDING YOUR ABILITY TO TRANSMIT AND RECEIVE INFORMATION FROM OR
          THROUGH FBarchive, AND YOU AGREE AND ACKNOWLEDGE THAT YOUR ABILITY TO
          ACCESS FBarchive MAY BE IMPAIRED.
        </p>

        <p>
          5. LIMITATION OF LIABILITY. EXCEPT INSOFAR AS THE FOLLOWING LIMITATION
          MAY BE PROHIBITED BY APPLICABLE LAW, WE SHALL NOT BE LIABLE TO YOU OR
          TO ANY THIRD PARTY FOR ANY CONSEQUENTIAL, INDIRECT, PUNITIVE, SPECIAL,
          OR INCIDENTAL DAMAGES, WHETHER FORESEEABLE OR UNFORESEEABLE
          (INCLUDING, BUT NOT LIMITED TO, LOSS OF PROFITS OR EARNING POWER, LOSS
          OF DATA, LOSSES DUE TO ERRORS OR INTERRUPTION IN AVAILABILITY OF THE
          SITE, UNAVAILABILITY OF ANY SERVICE, SERVER, OR COMMUNICATIONS
          FACILITY, OR DAMAGES DUE TO ACTS OR OMISSIONS OF OTHERS USING
          FBarchive), ARISING OUT OF OR RELATING TO FBarchive, INCLUDING WITHOUT
          LIMITATION YOUR AND OTHERS&rsquo; USE OF OR INABILITY TO USE
          FBarchive, OR YOUR RELIANCE UPON INFORMATION OBTAINED FROM OR THROUGH
          FBarchive, WHETHER BASED IN CONTRACT, TORT, STATUTORY, OR OTHER LAW.
          OUR TOTAL CUMULATIVE LIABILITY TO YOU ARISING OUT OF OR RELATED TO
          FBarchive (INCLUDING, WITHOUT LIMITATION, IN THE WAYS DESCRIBED IN THE
          PRECEDING SENTENCE), WHETHER BASED IN CONTRACT, TORT, STATUTORY, OR
          OTHER LAW, WILL NOT EXCEED THE AMOUNT, IF ANY, THAT YOU PAID US TO USE
          FBarchive IN THE TWELVE MONTHS PRECEDING THE CLAIM. THE DISCLAIMERS
          AND LIMITATIONS SET FORTH IN THIS SECTION SHALL APPLY TO THE MAXIMUM
          EXTENT PERMITTED BY APPLICABLE LAW, WHETHER OR NOT WE HAVE BEEN
          NEGLIGENT OR OTHERWISE AT FAULT.
        </p>

        <p>
          YOU ACKNOWLEDGE AND AGREE THAT THE WARRANTY DISCLAIMERS AND THE
          LIMITATIONS OF LIABILITY SET FORTH IN THESE TERMS OF SERVICE REFLECT A
          REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND US, AND THAT
          THESE LIMITATIONS ARE ESSENTIAL TO OUR WILLINGNESS TO MAKE FBarchive
          AVAILABLE TO YOU. EACH OF THESE DISCLAIMERS AND LIMITATIONS IS
          INTENDED TO BE SEPARATELY ENFORCEABLE, REGARDLESS OF WHETHER ANY OTHER
          REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
        </p>

        <p>
          YOU ACKNOWLEDGE THAT, FOR PURPOSES OF THE FOREGOING DISCLAIMERS AND
          LIMITATIONS, AS WELL AS THE INDEMNITY AND THIRD-PARTY PROVISIONS IN
          SECTIONS 6 AND 7 BELOW, THE TERMS &ldquo;WE,&rdquo; &ldquo;OUR,&rdquo;
          AND &ldquo;US&rdquo; INCLUDE THE CORPORATE BODY PRESIDENT AND FELLOWS
          OF HARVARD COLLEGE, ALSO KNOWN AS HARVARD UNIVERSITY, ITS VARIOUS
          SCHOOLS, THE MEMBERS OF ITS GOVERNING BOARDS, AND ITS OFFICERS,
          FACULTY MEMBERS, EMPLOYEES, FELLOWS, AND TO THE EXTENT THEY ARE
          WORKING ON FBarchive, ITS STUDENTS, CONTRACTORS, AND REPRESENTATIVES.
        </p>

        <p>
          CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES
          OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THE LAWS OR
          REGULATIONS OF THESE JURISDICTIONS APPLY TO YOU, SOME OR ALL OF THE
          ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU,
          AND YOU MAY HAVE ADDITIONAL RIGHTS.
        </p>

        <p>
          6. Indemnification. You agree to indemnify us (as defined for this
          purpose in Section 5 above) and hold us harmless from any and all
          claims, liabilities, damages, losses and expenses, including
          reasonable attorneys&rsquo; fees and costs, relating to or arising out
          of (a) your use or attempted use of FBarchive in violation of these
          Terms of Service; or (b) your violation of any law or rights of any
          third party in connection with your use of FBarchive.
        </p>

        <p>
          7. Links and Third-Party Affiliated Apps. FBarchive may include
          hyperlinks to websites or applications maintained or controlled by
          others, including apps developed and deployed by third parties for use
          on the MDC Platform. We are not responsible for and do not approve or
          endorse the contents or use of any of the information, products, or
          services that may be offered by these third-party websites or
          applications. If you decide to access linked third-party websites or
          applications, you do so at your own risk. You expressly relieve us
          from any liability arising from your use of any third-party website or
          application.
        </p>

        <p>
          8. Choice of Law. You agree that the Terms of Service and any claim or
          dispute arising out of or relating to the Terms of Service or your use
          of FBarchive will be governed by the laws of the Commonwealth of
          Massachusetts, excluding its conflicts of laws principles.
        </p>

        <p>
          You agree that all such claims and disputes will be heard and resolved
          exclusively in the federal or state courts located in and serving
          Middlesex or Suffolk County, Massachusetts, U.S.A. You consent to the
          personal jurisdiction of those courts over you for this purpose, and
          you waive and agree not to assert any objection to such proceedings in
          those courts (including any defense or objection of lack of proper
          jurisdiction or inconvenience of forum).
        </p>

        <p>
          9. Whole Agreement/ Amendments. These Terms of Service, together with
          the MyDataCan Terms of Service, comprise the entire agreement between
          you and Harvard with respect to your use of FBarchive and, as
          applicable, the MDC Platform. Harvard reserves the right to amend
          these Terms of Service at any time. FBarchive will send you notice of
          changes made to the Terms of Service within a reasonable time of
          making them, and by accessing FBarchive after receiving this notice,
          you agree to be bound by all the modified terms. Accordingly, you
          should periodically revisit this page to review the then-current Terms
          of Service.
        </p>
      </div>
      <Button
        onClick={() => {
          setAgreed(true)
          navigate('/')
        }}
        variant={'outlined'}
        fullWidth
        sx={{ my: 2 }}
      >
        agree
      </Button>
    </Box>
  )
}
