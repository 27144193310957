import React, { useEffect, useState, useContext, useMemo } from 'react'
import { Container, Box, Stack, CircularProgress } from '@mui/material'

import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import _ from 'lodash'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined'
import { useLocation } from 'react-router-dom'
import { useCopyToClipboard } from 'usehooks-ts'
import $axios from '../../tools/axiosWrapper'

export const Head = ({ user, doc, docid }) => {
  const theme = useTheme()
  const [liked, setLiked] = useState(null)
  const [load, setLoad] = useState(false)

  const [value, copy] = useCopyToClipboard()

  useEffect(() => {
    setLoad(true)
    $axios
      .get('/collection/list/' + 'bookmark')
      .then((response) => {
        let a = response.data.documents.map((item) =>
          item.replace('w32', '').replace('w31', '')
        )
        setLiked(
          a.includes(doc.documentid.replace('w32', '').replace('w31', ''))
        )
        setLoad(false)
      })
      .catch((error) => {
        setLoad(false)
      })
  }, [doc])

  return (
    <>
      <div
        className='page-doc-head'
        style={{ background: theme.palette.purple[1], overflow: 'hidden' }}
      >
        <Stack direction={'row'} spacing={2}>
          <motion.div
            whileTap={{ scale: 0.5 }}
            style={{ cursor: 'pointer', originX: 0.5, originY: 0.5 }}
          >
            {load ? (
              <CircularProgress size={24} />
            ) : liked ? (
              <BookmarkIcon onClick={() => {}} />
            ) : (
              <BookmarkBorderOutlinedIcon
                onClick={() => {
                  setLoad(true)
                  console.log(doc.documentid)
                  $axios
                    .post('/collection/update', {
                      title: '',
                      documents: [docid],
                      headings: '',
                    })
                    .then((response) => {
                      setLiked(true)
                      setLoad(false)
                    })
                    .catch((error) => {
                      $axios
                        .post('/collection/create', {
                          title: '',
                          docs: [docid],
                          headings: '',
                        })
                        .then((response) => {
                          setLiked(true)
                          setLoad(false)
                        })
                        .catch((error) => console.log(error))
                    })
                }}
              />
            )}
          </motion.div>
          <div style={{ fontSize: 15, fontWeight: 700 }}>{doc.title}</div>
        </Stack>
        <Stack direction={'row'} spacing={2}>
          <motion.div
            style={{ originX: 0.5, originY: 0.5 }}
            whileTap={{ scale: 0.5 }}
          >
            <ContentCopyIcon
              sx={{
                pl: 0.4,
                mr: 0,
                color: theme.palette.white[4],
                cursor: 'pointer',
              }}
              onClick={() => copy(doc.citation)}
            />
          </motion.div>
          <div
            style={{
              fontSize: 12,
              color: theme.palette.white[3],
              height: '15px',
              overflow: 'hidden',
            }}
          >
            {doc.citation}
          </div>
        </Stack>
      </div>
    </>
  )
}
