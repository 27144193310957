import { useEffect, useState, useContext, useMemo } from 'react'

import { Container, Grid, Typography, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'

import img2023May from './history_images/2023-may.png'
import img2023Jan from './history_images/2023-jan.png'
import img2022Nov from './history_images/2022-nov.png'
import img2022Feb from './history_images/2022-feb.png'
import img2022Jan from './history_images/2022-jan.png'
import img2021Nov from './history_images/2021-nov.png'
import img2021Oct from './history_images/2021-oct.png'

const imageStyle = {
  width: '100%',
  filter: 'grayscale(1) invert(1)',
}

const linkStyle = {
  color: '#7979f0',
}

const updateLog = {
  "May 2023": {
    "odoc12314": "This is the title of this document",
    "odoc38642": "This is yet another title"
  }
}

export const Updates = () => {
  const theme = useTheme()
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const elements = Object.entries(updateLog).map(arr => {
    const [date, docs] = arr;

    const docList = Object.entries(docs).map(arr => {
      const [docId, docTitle] = arr;
      return (
        <Typography key={docId} variant='body1'>
          <strong>{docId}</strong>: {docTitle}
        </Typography>
      )
    });

    return (
      <Stack key={date} spacing={2}>
        <Typography variant='h4'>{date}</Typography>
        {docList}
      </Stack>
    )
  })

  return (
    <>
      <Stack sx={{ minHeight: '100vh', py: 12 }} alignItems={'center'}>
        <Typography
          variant='h2'
          sx={{ fontWeight: 800, color: theme.palette.purple[5], mb: 8 }}
        >
          UPDATES TO THE COLLECTION
        </Typography>

        <Stack sx={{ width: 700 }} spacing={6}>
          {elements}
        </Stack>
      </Stack>
    </>
  )
}
