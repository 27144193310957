import { useEffect, useRef } from 'react'
import _ from 'lodash'
import './mentions.css'

import map from './map.png'

const Mentions = () => {
  const languages = [
    'English',
    'Hindi',
    'Bengali',
    'Arabic',
    'Burmese',
    'Russian',
    'Spanish',
    'Bhasa',
    'Portuguese',
    'German',
    'Korean',
    'Japanese',
    'Chinese',
    'Oromiffa',
    'Hebrew',
    'Vietnamese',
    'French',
  ]

  return (
    <>
      <div
        className={'gotham'}
        style={{
          fontWeight: 500,
          margin: '0px 0px 400px 0px',
        }}
      >
        <div
          style={{
            padding: '80px 40px',
            fontWeight: 500,
            fontSize: 20,
            textAlign: 'center',
          }}
        >
          A review of the Facebook archive documents revealed the scale of
          global engagement. <br />
          89 countries were mentioned and 15 languages encountered.
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '0 40px' }}>
            <div
              style={{
                fontWeight: 500,
                fontSize: 18,
                marginBottom: 20,
                textTransform: 'capitalize',
                display: 'flex',
              }}
            >
              <div>countries mentioned</div>
              <div
                style={{
                  marginLeft: 80,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 18,
                    textTransform: 'none',
                  }}
                >
                  <div>mentions:</div>

                  <div
                    style={{
                      width: '260px',
                      marginLeft: '40px',
                    }}
                  >
                    <div
                      className='gradient'
                      style={{
                        width: '100%',
                        height: 16,
                        margin: '0px 0px',
                        borderRadius: '10px',
                      }}
                    ></div>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        fontSize: 18,
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>1</div>
                      <div>25</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              style={{
                height: '40vw',
                width: '70vw',
              }}
              src={map}
            />
          </div>
          <div>
            <div
              style={{
                fontWeight: 500,
                fontSize: 18,
                marginBottom: 20,
                textTransform: 'capitalize',
              }}
            >
              languages encountered
            </div>
            {languages.map((d) => (
              <div
                style={{
                  fontWeight: 500,
                  fontSize: 18,
                  textTransform: 'uppercase',
                }}
              >
                {d}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Mentions
