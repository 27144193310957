import { useEffect, useState, useContext, useMemo } from 'react'
import { useLocation, Link } from 'react-router-dom'
import {
  Container,
  Box,
  Stack,
  Modal,
  Typography,
  TextField,
  Button,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import _ from 'lodash'

export const FormStart = ({ open, setOpen, current }) => {
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const theme = useTheme()

  useEffect(() => {}, [])
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    outline: 'none',
    p: 4,
  }
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Stack sx={style} justifyContent={'center'} spacing={2}>
          <motion.img style={{ width: '90%' }} src={current.src}></motion.img>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Submit a comment associated with this image
          </Typography>
          <TextField />

          <Button onClick={() => {}}>submit</Button>
        </Stack>
      </Modal>
    </>
  )
}
