import _, { set } from 'lodash'
import {
  Autocomplete,
  TextField,
  Stack,
  Box,
  CircularProgress,
} from '@mui/material'
import { useEffect, useState } from 'react'
import $axios from '../../tools/axiosWrapper'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { motion } from 'framer-motion'

export const Tags = ({ user, current, doc }) => {
  const [text, setText] = useState('')
  const [alltags, setAlltags] = useState(null)
  const [clear, setClear] = useState(0)
  const [tagLoad, setTagLoad] = useState(false)

  const GET_TAGS = () => {
    $axios
      .get('/tag/listall')
      .then((response) => {
        console.log(response)
        setAlltags(response.data)
      })
      .catch((er) => console.log(er))
      .finally(() => setTagLoad(false))
  }

  useEffect(() => {
    GET_TAGS()
  }, [])

  return (
    <>
      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'space-between',
          width: '100%',
          height: '40px',
          overflow: 'hidden',
        }}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ height: '40px' }}
        >
          <Autocomplete
            freeSolo
            disablePortal
            id={'tag search' + clear}
            options={_.uniqBy(alltags, 'tag').map((d) => d.tag)}
            sx={{ width: 200 }}
            size='small'
            value={text}
            onInputChange={(e, v) => setText(v)}
            renderInput={(params) => (
              <TextField
                {...params}
                // InputProps={{ style: { fontSize: 13 } }}
                variant='standard'
              />
            )}
          />
          <motion.div
            style={{
              padding: '6px 6px 9px 6px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              marginLeft: '4px',
            }}
            whileTap={{ scale: 0.95 }}
            animate={{ opacity: text ? 1 : 0.2 }}
            transition={{ duration: 0.1 }}
            onClick={() => {
              setTagLoad(true)
              text &&
                $axios
                  .post('/tag/docbytag_add', {
                    tag: _.lowerCase(text),
                    imageid: current + '_w32',
                    referrerdoc: doc.documentid,
                  })
                  .then((response) => {
                    console.log('response', response)
                  })
                  .catch((er) => console.log(er))
                  .finally(() => {
                    GET_TAGS()
                  })

              // text &&
              //   $axios
              //     .post('/tag/add', {
              //       tag: _.lowerCase(text),
              //       imageid: current,
              //     })
              //     .then((response) => {
              //       console.log('response', response)
              //     })
              //     .catch((er) => console.log(er))
              //     .finally(() => {
              //       GET_TAGS()
              //       setText('')
              //       setClear(clear + 1)
              //     })
            }}
          >
            {tagLoad ? <CircularProgress size={20} /> : <AddCircleIcon />}
          </motion.div>
        </Stack>

        <Box
          // direction={'row'}
          sx={{
            display: 'inline',
            whiteSpace: 'nowrap',
            overflowX: 'scroll',
            overflowY: 'hidden',
            height: '40px',
            minWidth: '100px',
            // flexGrow: 1,
          }}
          className={'hidden-scrollbar'}
        >
          {alltags &&
            alltags
              .filter(
                (t) =>
                  t.imageid?.replace('_w32', '').replace('_w31', '') ==
                  current?.replace('_w32', '').replace('_w31', '')
              )
              .map((d, i) => (
                <div
                  key={d.tagid}
                  style={{
                    display: 'inline-block',
                    fontSize: 13,
                    margin: '2px 4px',
                    padding: '6px 12px',
                    whiteSpace: 'no-wrap',
                  }}
                >
                  {'#' + d.tag}
                </div>
              ))}
        </Box>
      </Stack>
    </>
  )
}
