import { PanelResizeHandle } from 'react-resizable-panels'
import { useTheme } from '@mui/material/styles'

export const ResizeHandle = ({}) => {
  const theme = useTheme()
  return (
    <PanelResizeHandle>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          background: theme.palette.purple[1],
          height: 12,
          margin: '8px 0px',
        }}
      >
        <svg viewBox='0 0 36 4' width={36}>
          <path
            stroke='white'
            strokeWidth={4}
            style={{ strokeLinecap: 'round' }}
            d='M 2 2 L 34 2'
          />
        </svg>
      </div>
    </PanelResizeHandle>
  )
}
