import { useEffect, useState, useContext, useMemo } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import {
  Container,
  Box,
  Stack,
  Modal,
  Typography,
  TextField,
  Button,
} from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'

import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import _ from 'lodash'
import $axios from '../../tools/axiosWrapper'

export const Like = ({ d, i }) => {
  const theme = useTheme()
  const [like, setLike] = useState({ title: '', images: [] })

  useEffect(() => {
    $axios
      .get('/doc/list/' + d)
      .then((response) => {
        setLike(response?.data?.contents[0])
      })
      .catch((error) => {})
  }, [d])
  console.log('d', d)

  return (
    <Stack sx={{ background: theme.palette.purple[1], p: 2, borderRadius: 2 }}>
      <Link
        className={'link'}
        to={'../doc/' + d.replace('w32', '').replace('w31', '')}
        // state={{ results: results, img: props.id }}
      >
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <Typography variant={'subtitle1'}>{'#' + like.title}</Typography>
          <LinkIcon />
        </Stack>
      </Link>

      <Stack sx={{ p: 2 }}>
        <Typography variant={'subtitle1'} color={theme.palette.white[3]}>
          {like &&
            like?.images?.length +
              ' image' +
              (like?.images?.length > 1 ? 's' : '') +
              ':'}
        </Typography>
        <Stack direction={'row'} sx={{ py: 1, overflowX: 'scroll' }}>
          {like &&
            like?.images
              ?.map((d) => d.replace('_w32', '').replace('_w31', ''))
              .map((d, i) => (
                <motion.div
                  style={{
                    border: '1px solid ' + theme.palette.white[3],
                    margin: '4px',
                    padding: '4px',
                    color: theme.palette.white[3],
                    borderRadius: '6px',
                  }}
                >
                  {d}
                </motion.div>
              ))}
        </Stack>
      </Stack>
    </Stack>
  )
}
