import { useEffect, useState, useContext, useMemo } from 'react'

import { Container, Grid, Typography, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import charts from './a.png'



const linkStyle = {
  color: '#7979f0',
}


export const About = () => {
  const theme = useTheme()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Stack sx={{ minHeight: '100vh', py: 12 }} alignItems={'center'}>
        <Typography
          variant='h2'
          sx={{ fontWeight: 800, color: theme.palette.purple[5], mb: 8 }}
        >
          ABOUT
        </Typography>

        <Stack sx={{ width: 700 }} spacing={6}>
          <Stack spacing={2}>
            <Typography variant='body1'>
              We have founded <b>FBarchive</b> with a singular mission: to allow society to reap the rewards of social media without the associated harm. By offering researchers, journalists, and the general public searchable and indexed access to carefully curated internal Facebook documents, our aim is to foster solutions to the myriad of issues highlighted. These concerns are not limited to Meta's offerings but resonate across all social media platforms.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='body1'>
              <b>FBarchive</b> consists of images of documents taken by Frances Haugen, the whistleblower who disclosed tens of thousands of pages of internal Facebook research. Teams at Facebook used social science and data analytics to help the company's leadership understand adverse consequences from their decisions and technology designs. The documents provide a wealth of material for academic and journalistic study and a unique opportunity for collaborative investigation and shared understanding. We have redacted personal names and other sensitive content that appear in the images for privacy reasons. We are unable to publicly share the raw material as we believe it would be irresponsible to release images without this type of redaction and curation.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='body1'>
              <b>FBarchive</b> is a research project across The Public Interest Tech Lab and Harvard Kennedy School’s Shorenstein Center on Media, Politics, and Public Policy. 
              We welcome collaborators and participants.

              <br />
              <br />
              For questions about the archive, you can contact Dr. Gabrielle Malina, the project manager, at <a href="mailto:gabrielle@fbarchive.org" style={linkStyle}>gabrielle@fbarchive.org</a>.

            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
