import { useEffect, useState, useContext, useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { Stack } from '@mui/material'

export const Loading = ({ user }) => {
  const theme = useTheme()

  const POINTS = [
    {
      start: {
        x1: 0,
        y1: 32,
        x2: 64,
        y2: 32,
      },
      finish: {
        x1: 20.37,
        y1: 9,
        x2: 54.71,
        y2: 9,
      },
    },
    {
      start: {
        x1: 0,
        y1: 36,
        x2: 64,
        y2: 36,
      },
      finish: {
        x1: 9.56,
        y1: 20,
        x2: 47.12,
        y2: 20,
      },
    },
    {
      start: {
        x1: 0,
        y1: 40,
        x2: 64,
        y2: 40,
      },
      finish: {
        x1: 16,
        y1: 32,
        x2: 60,
        y2: 32,
      },
    },
    {
      start: {
        x1: 0,
        y1: 44,
        x2: 64,
        y2: 44,
      },
      finish: {
        x1: 7.79,
        y1: 44,
        x2: 54.71,
        y2: 44,
      },
    },
  ]
  return (
    <>
      <Stack
        style={{
          width: '100vw',
          height: '90vh',
        }}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <motion.svg
          alt='fb archive logo'
          viewBox='0 0 64 50'
          style={{
            width: '12vw',
            minWidth: '100px',
            maxWidth: '200px',
            strokeWidth: 4.5,
            stroke: theme.palette.white[3],
          }}
        >
          {POINTS.map((d, i) => (
            <motion.line
              key={i}
              style={{ originX: 0.5 }}
              initial={{ ...d.start, scaleX: 0.9 }}
              animate={{ ...d.finish, scaleX: 1 }}
              transition={{
                type: 'spring',
                duration: 1,
                delay: 0.1,
                scaleX: {
                  delay: 0.3 + i * 0.15,
                  duration: 0.6,
                  repeat: Infinity,
                  repeatType: 'reverse',
                  ease: 'easeInOut',
                },
              }}
            />
          ))}
        </motion.svg>
      </Stack>
    </>
  )
}
