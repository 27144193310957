import React, { useEffect, useState, useContext, useMemo } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { TextField, Box, Stack, Autocomplete } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import _, { set } from 'lodash'
import { useCopyToClipboard } from 'usehooks-ts'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
export const Citation = ({ current }) => {
  const theme = useTheme()
  const [value, copy] = useCopyToClipboard()

  return (
    <>
      <Stack
        sx={{
          width: '100%',
          height: '48px',
          px: 4,
          py: '4px',
          borderRadius: '16px',
          border: 'solid 1px' + theme.palette.purple[4],
          mt: '20px',
          textOverflow: 'hidden',
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        direction={'row'}
        alignItems={'center'}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            fontSize: 12,
            color: theme.palette.white[4],
            lineHeight: '1.6em',
          }}
        >
          {current?.citation}
        </div>
        <motion.div
          style={{ padding: '0 10px' }}
          whileTap={{ scale: 0.95 }}
          whileHover={{ scale: 1.2 }}
          onClick={() => {}}
        >
          <Link
            to={
              current?.citation &&
              '/' +
                current?.citation
                  .match(/\URL:(.*)/)[1]
                  .trim()
                  .replace('fbarchive.org/user/', '')
                  .replace('w32', '')
                  .replace('w31', '')
            }
            target='_blank'
          >
            <OpenInNewIcon sx={{ fill: theme.palette.white[4] }} />
          </Link>
        </motion.div>
        <motion.div
          style={{ padding: '0 10px' }}
          whileTap={{ scale: 0.95 }}
          whileHover={{ scale: 1.2 }}
          onClick={() => {
            copy(current?.citation)
          }}
        >
          <ContentCopyIcon sx={{ fill: theme.palette.white[4] }} />
        </motion.div>
      </Stack>
    </>
  )
}
