import React, { useEffect, useState, useContext, useRef } from 'react'
import { Outlet, Link, useNavigate } from 'react-router-dom'
import { Avatar, Box, Stack, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { motion } from 'framer-motion'
import { useTheme } from '@mui/material/styles'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import CloseIcon from '@mui/icons-material/Close'
import FavoriteIcon from '@mui/icons-material/Favorite'
import LogoutIcon from '@mui/icons-material/Logout'
import TagIcon from '@mui/icons-material/Tag'
import { Button, Popover } from '@mui/material'
import $axios from '../../tools/axiosWrapper'
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark'
export const Drawerr = ({ user, drawer, setDrawer }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const signOut = () => {
    $axios.get('/auth/logout').then(() => {
      window.location.href = '/'
    })
  }

  return (
    <>
      <React.Fragment key={'right'}>
        <Drawer
          anchor={'right'}
          open={drawer}
          // onClose={toggleDrawer(anchor, false)}
        >
          <Stack
            sx={{
              width: 300,
              height: '100vh',
              background: theme.palette.purple[2],
            }}
          >
            <div
              style={{
                width: '100%',
                height: 100,
                background: theme.palette.purple[4],
              }}
            >
              <motion.div
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.1 }}
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => setDrawer(false)}
              >
                <CloseIcon sx={{ transform: 'scale(3)' }} />
              </motion.div>
            </div>

            <div
              style={{
                width: '100%',
                height: 100,
              }}
            >
              <motion.div
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.1 }}
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  navigate('/collection')
                }}
              >
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  spacing={3}
                  justifyContent={'start'}
                  sx={{ width: '100%', pl: 9 }}
                >
                  <CollectionsBookmarkIcon sx={{ transform: 'scale(1.5)' }} />
                  <Typography sx={{ fontSize: 20 }}>COLLECTION</Typography>
                </Stack>
              </motion.div>
              <Box
                sx={{
                  width: '100%',
                  height: 2,
                  background: theme.palette.white[4],
                }}
              ></Box>
            </div>

            <div
              style={{
                width: '100%',
                height: 100,
              }}
            >
              <motion.div
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.1 }}
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  navigate('/saved')
                }}
              >
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  spacing={3}
                  justifyContent={'start'}
                  sx={{ width: '100%', pl: 9 }}
                >
                  <BookmarkIcon sx={{ transform: 'scale(1.5)' }} />
                  <Typography sx={{ fontSize: 20 }}>SAVED</Typography>
                </Stack>
              </motion.div>
              <Box
                sx={{
                  width: '100%',
                  height: 2,
                  background: theme.palette.white[4],
                }}
              ></Box>
            </div>

            <div
              style={{
                width: '100%',
                height: 100,
              }}
            >
              <motion.div
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.1 }}
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  navigate('/tags')
                }}
              >
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  spacing={3}
                  justifyContent={'start'}
                  sx={{ width: '100%', pl: 9 }}
                >
                  <TagIcon sx={{ transform: 'scale(1.5)' }} />
                  <Typography sx={{ fontSize: 20 }}>TAGS</Typography>
                </Stack>
              </motion.div>
              <Box
                sx={{
                  width: '100%',
                  height: 2,
                  background: theme.palette.white[4],
                }}
              ></Box>
            </div>

            <div
              style={{
                width: '100%',
                height: 100,
              }}
            >
              <motion.div
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.1 }}
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={signOut}
              >
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  spacing={3}
                  sx={{ width: '100%', pl: 9 }}
                  onClick={() => {
                    $axios.get('/auth/logout')
                  }}
                >
                  <LogoutIcon sx={{ transform: 'scale(1.5)' }} />
                  <Typography sx={{ fontSize: 20 }}>LOGOUT</Typography>
                </Stack>
              </motion.div>
              <Box
                sx={{
                  width: '100%',
                  height: 2,
                  background: theme.palette.white[4],
                }}
              ></Box>
            </div>
          </Stack>
        </Drawer>
      </React.Fragment>
    </>
  )
}
