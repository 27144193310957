import React, { useEffect, useState, useContext, useMemo } from 'react'

import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import _ from 'lodash'

export const SideItem = ({ d, i, reff, imgs, current, setCurrent }) => {
  const theme = useTheme()

  return (
    <>
      <motion.div
        whileTap={{ scale: 0.95 }}
        transition={{ duration: 0.1 }}
        onClick={() => setCurrent(d)}
        key={d.imageid + '-' + i}
        style={{ cursor: 'pointer', scrollMarginBottom: '160px' }}
        id={d.imageid.replace('_w32', '')}
        ref={(el) => (reff.current[i] = el)}
      >
        <img
          onError={(err) => console.log(err)}
          src={d.imgurl}
          alt=' '
          onDragStart={(event) => event.preventDefault()}
          style={{
            userSelect: 'none',
            borderColor:
              current && current.imageid == d.imageid
                ? 'orange'
                : 'transparent',
            borderWidth: 6,
            borderStyle: 'solid',
            opacity: current && current.imageid == d.imageid ? 1 : 0.8,
          }}
        />
        <motion.div
          style={{
            textAlign: 'center',
            width: '100%',
            marginBottom: '12px',
            marginTop: '-4px',
            fontWeight: current && current.imageid == d.imageid ? 800 : 400,
          }}
        >
          {d.imageid.replace('_w32', '')}
        </motion.div>
      </motion.div>
    </>
  )
}
