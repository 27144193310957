import { useEffect, useState, useContext, useMemo } from 'react'
import { Container, Grid, Typography, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export const Redaction = () => {
    const theme = useTheme();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <Stack sx={{ minHeight: '100vh', py: 12 }} alignItems={'center'}>
                <Typography
                    variant='h2'
                    sx={{ fontWeight: 800, color: theme.palette.purple[5], mb: 8 }}
                >
                    REDACTION GUIDELINES         
                </Typography>

                <Stack sx={{ width: 700 }} spacing={6}>
                    <Stack spacing={2}>
                        <Typography variant='h4'>Redaction Guidelines for FBarchive - Green Version (public version with most privacy/security protection measures)</Typography>
                        <Typography variant='body1'>
                            <ol>
                                <li>Redaction guidelines created by Privacy and Safety Board, and all redactions made both in text output and in the images</li>
                                <li>
                                    Non-Social Media Content Redaction Guidelines
                                    <ol type="a">
                                        <li>
                                            Full names - Do not redact if:
                                            <ol type="i">
                                                <li>name refers to a key decision maker at Meta (using internally developed list)</li>
                                                <li>name is a “public figure” (celebrity, politician, journalist, academic, athletes, social media influencer, etc.) - determined according to existing knowledge of reviewers without aid of background research (because each reviewer has differing knowledge about the content and researching every name was not scalable)</li>
                                                <li>name refers to external companies/ organizations/ researchers/ presenters/ consultants</li>
                                                <li>name attributed to a news article or some other publicly available content</li>
                                                <li>name appears within a bibliography or citation so long as the document being cited is not a Meta internal document (if provided URL is internal Meta link)</li>
                                            </ol>
                                        </li>
                                        <li>
                                            Partial names (where full identity was not immediately apparent) - Internet research and searching across other images within the same document was used to attempt to determine full identity of partial name. If full identity could be determined, partial name was redacted if not referencing a person on list of key decision makers at Meta or not a “public figure.” Partial name was redacted if full identity could not be determined.
                                        </li>
                                        <li>
                                            User ID numbers for Facebook platform were redacted as they could be used to identify users in conflict with our Social Media Content Redaction Guidelines as described below
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Social Media Content Redaction Guidelines (“social media content” refers to names of post/comment authors, names within posts/comments, account names/handles, profile pictures/thumbnails, and images more generally)
                                    <ol type="a">
                                        <li>
                                            Authors of posts/comments
                                            <ol type="i">
                                                <li>
                                                    Redact name, account handle, and profile picture/thumbnail if (1) post/comment is not “public” (see criteria below), (2) author is not on list of key decision makers at Meta, (3) author is not a “public figure” (celebrity, politician, journalist, academic, athletes, social media influencer, etc.), (4) author is not an aggregate entity (things that represent more than one individual, like a “page” or group)
                                                    <ol>
                                                        <li>
                                                            “Public” post/comment defined as: (1) anything on Twitter that does not have lock icon and is not a direct message, (2) Facebook posts with the globe icon, (3) Facebook or Instagram posts that are specified as “sponsored” or “paid for”
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            Names and pictures (showing a face to degree of theoretically being able to visually identify someone) within posts/comments
                                            <ol type="i">
                                                <li>Do not redact if names/pictures are (1) of public figures or (2) are obviously the name/picture of the author of the post/comment</li>
                                                <li>
                                                    Situations where names/pictures are not public figures or are not representative of the author were determined on case-by-case basis
                                                    <ol>
                                                        <li>Reasoning was that there was no way to create scalable guidelines that did not either extremely over-redact (diminishing impact of the problematic social media content) or under-redact (leaving privacy concerns)</li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Programming code or analysis results revealing proprietary platform information were redacted only if Meta explicitly requested AND our computer science experts on the Privacy and Safety Board agreed that the content was proprietary.
                                </li>
                                <li>
                                    Content representing substantive legal advice/counsel for Meta only if Meta explicitly requested AND our legal experts on the Privacy and Safety Board agreed that content represented substantive legal advice/counsel.
                                </li>
                            </ol>
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}