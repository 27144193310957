import { useState, useEffect, useRef, useContext } from 'react'
import _ from 'lodash'
import './human.css'
import human from './human.png'

const Human = () => {
  return (
    <main className='human'>
      <img src={human} style={{ width: '100vw' }} />
    </main>
  )
}

export default Human
